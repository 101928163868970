.Toastify__toast-container {
  width: 530px;
  opacity: 1;
  transform: none;
}
.Toastify__toast {
  padding: 1rem 2rem;
  border-radius: 3px;
  color: $body-color;
}
.Toastify__progress-bar--default {
  background: $green;
}
