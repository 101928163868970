.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.emoji {
  min-width: 10px;
}

.transaction-page {
  width: 50%;
  min-width: 650px;
  text-align: left;
  margin-left: 24px;
  margin-top: 100px;
}

.transaction-row {
}

.header-buttons {
  float: right;
  font-size: 18px;
  margin-right: 15px;
  margin-top: 10px;
}

.header-brand {
  font-size: 24px;
  font-weight: bolder;
  color: #174f7c;
}

.btn {
  padding: 5px;
}

.App-header {
  background-color: #282c34;
  text-align: left;
  min-height: 60px;
  padding-top: 15px;
  padding-left: 15px;
  font-size: calc(10px + 2vmin);
  color: #174f7c;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(3, 49, 86, 0.2);
}

.App-link {
  font-size: 18px;
  font-weight: bolder;
  color: #174f7c;
  box-shadow: 0 1px 2px rgba(3, 49, 86, 0.2);
}

input {
  font-size: 18px;
  font-weight: bolder;
  color: #174f7c;
  margin: 10px;
  text-align: center;
  min-width: 350px;
}

.App-link:hover {
  color: #468ca8;
  box-shadow: 0 2px 4px rgba(3, 49, 86, 0.5);
}

.main {
  margin-top: 5em;
}

input {
  text-align: left;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.thread-list {
  margin-left: 0px;
  padding-right: 0px;
  padding-left: 0px;
  border-right: 1px solid #e6e8eb;
  border-left: 1px solid #ddd;
  background-color: #f8f8f8;
  max-height: 90vh;
}

.thread-details {
  min-height: 90vh;
  max-height: 90vh;
  overflow-y: scroll;
  padding-bottom: 5rem;
}

.app-page {
  min-height: 90vh;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.thread-details::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.thread-details {
  -ms-overflow-style: none;
}

.new-thread-item {
  text-decoration: none;
}

.new-thread-item a {
  text-decoration: none;
}

.thread-list-item {
  border: none;
  border-radius: 4px;
  padding: 8px 10px;
  margin: 0 0px;
  border-bottom: var(--hp-border, 1px solid #e6e8eb);
  background-color: #f8f8f8;
  position: relative;
}

.thread-fill-icon {
  position: absolute;
  color: #36b37e;
  top: 5px;
  right: 5px;
}

.thread-list-item:hover {
  border: 2px solid #d6e8fa;
  border-radius: 0px;
}

.thread-selected {
  background-color: #d6e8fa;
  border: 0px solid #d6e8fa;
  border-radius: 0px;
}

.response-info {
  color: #282c34;
  font-size: 14px;
}

.threads-page-container {
  border-top: 1px solid #e6e8eb;
}

.message-item {
  margin: 8px;
  background-color: rgb(240, 240, 242);
  color: #282c34;
  border-radius: 4px;
  padding: 8px;
}

.has-responded-wrapper {
  color: rgba(0, 0, 0, 0.4);
  margin-top: 10px;
  border-top: "1px solid #ddd";
}

.time-label {
  color: rgba(0, 0, 0, 0.4);
  display: block;
  font-weight: 400;
  margin: 12px 0 12px 20px;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  font-size: 14px;
}

.sender-label {
  color: rgba(0, 0, 0, 0.4);
  display: block;
  font-weight: 600;
  margin: 4px 0 4px 10px;
  width: 100%;
  text-align: left;
  font-size: 14px;
}

.self-sender {
  background-color: rgb(0, 153, 255);
  color: #fff;
  margin: 8px;
  border-radius: 4px;
  padding: 8px;
  font-weight: 400;
}

/* EDITOR TODO _ TEST AND MOVE */
.RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-size: 14px;
  padding: 15px;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: "Hoefler Text", "Georgia", serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: "Inconsolata", "Menlo", "Consolas", monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: "Helvetica", sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}

.public-DraftEditorPlaceholder-root {
  color: #999;
}

.modal-wrapper {
  padding-top: 0px;
}

#HW_badge_cont {
  position: absolute !important;
  left: 10px;
  top: -8px;
}

.new-wrapper {
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  padding-right: 0px;
  cursor: pointer;
}

.new-wrapper:hover {
  color: #468ca8;
  font-weight: 600;
}

.invite-container {
  padding-left: 24px;
  padding-right: 24px;
}

.invite-panel {
  padding: 10px;
  border-radius: 8px;
  background-color: white;
  text-align: center;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
}
