.testimonial {

	&.has-bg-color {
		position: relative;
		background-color: transparent;
		z-index: auto;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0%;
			width: 100%;
			height: calc(100% - 225px);
			background: get-color(dark, 1);
			z-index: -3;
		}
	}	

	.tiles-item-inner {
		background: get-color(light, 1);
	}

	.testimonial-item-name {
		color: get-color(secondary, 2);
		
		a {
			color: get-color(secondary, 2) !important;
			text-decoration: none !important;
		}
	}	

	&.invert-color,
	.invert-color {

		.tiles-item-inner {
			background: get-color(dark, 2);
		}
		
		.testimonial-item-name {
			color: get-color(alert, success);
			
			a {
				color: get-color(alert, success) !important;
			}
		}			
	}
}

.testimonial-item-content {
	position: relative;

	&::before {
		content: '';
		display: block;
		margin-bottom: 16px;
        width: 21px;
        height: 18px;
        background-image: inline-svg('<svg width="21" height="18" xmlns="http://www.w3.org/2000/svg"><path d="M9.216 1.008C7.488 4.8 6.624 7.656 6.624 9.576s.72 3.504 2.16 4.752c-.528.912-1.14 1.632-1.836 2.16-.696.528-1.572.792-2.628.792-1.296 0-2.34-.444-3.132-1.332C.396 15.06 0 13.944 0 12.6c0-2.112.816-4.404 2.448-6.876C4.08 3.252 5.568 1.344 6.912 0l2.304 1.008zm11.16 0c-1.728 3.792-2.592 6.648-2.592 8.568s.72 3.504 2.16 4.752c-1.056 1.968-2.544 2.952-4.464 2.952-1.296 0-2.34-.444-3.132-1.332-.792-.888-1.188-2.004-1.188-3.348 0-2.112.816-4.404 2.448-6.876 1.632-2.472 3.12-4.38 4.464-5.724l2.304 1.008z" fill="' + get-color(secondary, 1) + '" fill-rule="evenodd"/></svg>');
        background-repeat: no-repeat;		
	}
}