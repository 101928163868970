.sidebar {
  background-color: #f7f6f3;

  li {
    padding: 7px 0;
    &.nav-indent {
      padding-left: 30px;
    }
  }
  a {
    color: #333;
    text-decoration: none;
    &:hover {
      background-color: #e8e7e5;
      border-radius: 4px;
    }
    .fi {
      margin: 0 15px;
      font-size: 20px;
    }
  }
  .active {
    background-color: #e8e7e5;
  }
  .flaticon-shuffle-1::before {
    transform: rotate(180deg);
  }

  .posthog-title {
    font-weight: 500;
    font-size: 20px;
    color: #333;
    padding-left: 15px;
    margin-top: 8px;
  }

  .logo {
    margin-left: 15px;
    width: 35px;
    height: 43px;
  }

  .logo-row {
    padding: 15px 15px 3rem;
  }
  .invite-team {
    position: fixed;
    bottom: 2rem;
    text-align: center;
    margin-left: -15px;
  }
}
